import * as React from "react";
import Navbar from "../sections/Navbar/Navbar";
import Footer from "../sections/Footer/Footer";
import "./global.css";
import "@progress/kendo-theme-default/dist/all.css";
import "../fonts/stylesheet.css";

const text = {
  fontFamily: "Manrope",
};


const tipPage = () => {
    return (
      <main>
    <Navbar />

    {/* <center><h1> Registrations Opening Soon!</h1></center> */}

    <div style={{
      display: 'flex',
      // justifyContent: 'center',
      alignItems: 'center',
      // height: '100vh',
    }}> 
      <iframe
        src="https://lu.ma/embed-checkout/evt-Z59SFmqtU4Ikqbl"
        width="100%"
        height="500px">
      </iframe>
    </div>

    <Footer />
  </main>
  );
};
export default tipPage;